exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-applyforjob-js": () => import("./../../../src/pages/applyforjob.js" /* webpackChunkName: "component---src-pages-applyforjob-js" */),
  "component---src-pages-careers-job-description-js": () => import("./../../../src/pages/careers/jobDescription.js" /* webpackChunkName: "component---src-pages-careers-job-description-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-experiencelifeatgoken-js": () => import("./../../../src/pages/experiencelifeatgoken.js" /* webpackChunkName: "component---src-pages-experiencelifeatgoken-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gokenindia-js": () => import("./../../../src/pages/gokenindia.js" /* webpackChunkName: "component---src-pages-gokenindia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-advancedtechnology-js": () => import("./../../../src/pages/industry/advancedtechnology.js" /* webpackChunkName: "component---src-pages-industry-advancedtechnology-js" */),
  "component---src-pages-industry-aerospace-js": () => import("./../../../src/pages/industry/aerospace.js" /* webpackChunkName: "component---src-pages-industry-aerospace-js" */),
  "component---src-pages-industry-automotive-js": () => import("./../../../src/pages/industry/automotive.js" /* webpackChunkName: "component---src-pages-industry-automotive-js" */),
  "component---src-pages-industry-manufacturing-js": () => import("./../../../src/pages/industry/manufacturing.js" /* webpackChunkName: "component---src-pages-industry-manufacturing-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-leadership-index-js": () => import("./../../../src/pages/leadership/index.js" /* webpackChunkName: "component---src-pages-leadership-index-js" */),
  "component---src-pages-leadership-leader-js": () => import("./../../../src/pages/leadership/Leader.js" /* webpackChunkName: "component---src-pages-leadership-leader-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-automation-js": () => import("./../../../src/pages/services/automation.js" /* webpackChunkName: "component---src-pages-services-automation-js" */),
  "component---src-pages-services-costengineering-js": () => import("./../../../src/pages/services/costengineering.js" /* webpackChunkName: "component---src-pages-services-costengineering-js" */),
  "component---src-pages-services-engineeringdesign-js": () => import("./../../../src/pages/services/engineeringdesign.js" /* webpackChunkName: "component---src-pages-services-engineeringdesign-js" */),
  "component---src-pages-services-gokengo-js": () => import("./../../../src/pages/services/gokengo.js" /* webpackChunkName: "component---src-pages-services-gokengo-js" */),
  "component---src-pages-services-knowledgesolution-js": () => import("./../../../src/pages/services/knowledgesolution.js" /* webpackChunkName: "component---src-pages-services-knowledgesolution-js" */),
  "component---src-pages-services-languageservices-js": () => import("./../../../src/pages/services/languageservices.js" /* webpackChunkName: "component---src-pages-services-languageservices-js" */),
  "component---src-pages-services-training-js": () => import("./../../../src/pages/services/training.js" /* webpackChunkName: "component---src-pages-services-training-js" */),
  "component---src-pages-services-validation-js": () => import("./../../../src/pages/services/validation.js" /* webpackChunkName: "component---src-pages-services-validation-js" */),
  "component---src-pages-solutions-productdevelopment-js": () => import("./../../../src/pages/solutions/productdevelopment.js" /* webpackChunkName: "component---src-pages-solutions-productdevelopment-js" */),
  "component---src-pages-solutions-productivity-js": () => import("./../../../src/pages/solutions/productivity.js" /* webpackChunkName: "component---src-pages-solutions-productivity-js" */),
  "component---src-pages-staffing-js": () => import("./../../../src/pages/staffing.js" /* webpackChunkName: "component---src-pages-staffing-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-insight-js": () => import("./../../../src/templates/insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-leader-template-js": () => import("./../../../src/templates/LeaderTemplate.js" /* webpackChunkName: "component---src-templates-leader-template-js" */)
}

